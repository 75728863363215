import React from 'react';
import styled from 'styled-components';
import {
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  TextField,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import moment from 'moment';
import { navigate } from 'gatsby-link';
// import ReCAPTCHA from 'react-google-recaptcha';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Title from '../components/styles/title';

import ContactUsStyles from '../components/styles/contact-us-styles';

const SubHeader = styled.h4`
  padding-bottom: 20px;
`;

const PricePoints = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px;
`;

// const RadioButtonsContainer = styled.div`
//   align-items: flex-start;
//   display: flex;
//   flex-direction: row;
//   justify-content: center;

//   .radioButton {
//     align-items: center;
//     display: flex;
//     padding: 6px;
//   }

//   span {
//     padding-left: 6px;
//   }

//   /* @media (max-width: 768px) {
//     flex-direction: column;
//     align-items: center;
//   } */
// `;

const Header = styled.p`
  max-width: 800px;
  padding: 20px;
  padding-bottom: 60px;

  .bold {
    font-weight: bold;
  }
`;

const CenterRow = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;

  .percentInput {
    max-width: 136px;
    margin: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SurveryCenterContainer = styled.div`
  margin: 0 auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Form = styled.form`
  text-align: left;

  @media (max-width: 768px) {
    padding: 20px;

    input {
      width: 95%;
    }
  }
`;

function encode(data) {
  return (
    Object.keys(data)
      // eslint-disable-next-line prefer-template
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&')
  );
}

const useStyles = makeStyles(theme => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  percentInput: {
    maxWidth: '136px',
    margin: '10px',
  },
  radio: {
    height: 'auto',
    marginBottom: '0',
    margin: '0',
  },
  textField: {
    marginLeft: '8px',
  },
}));

const SurveyCenter = () => {
  const classes = useStyles();

  const [state, setState] = React.useState({
    'Week Ending': moment().format('l'),
  });
  const [selectedDate, setSelectedDate] = React.useState(moment().format('l'));
  const [radioValue, setRadioValue] = React.useState();

  const handleChange = e => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  React.useEffect(() => {
    handleChange({
      target: {
        name: 'Zone',
        value: radioValue,
      },
    });
  }, [radioValue]);

  const handleSubmit = e => {
    e.preventDefault();
    const form = e.target;
    // eslint-disable-next-line no-undef
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch(error => {
        // eslint-disable-next-line no-console
        console.log('error: ', error);
      });
  };

  return (
    <Layout>
      <SEO title="Survey Center" />
      <Title>Weekly Sales Report Survey</Title>

      <ContactUsStyles>
        <SurveryCenterContainer>
          <Header>
            <p>
              Please fill out the survey below. This data helps us track the
              health of the LC franchise system. Participants will receive a
              detailed reporting once a week and a complete analysis at the end
              of the period. Thank you! Thank you!
            </p>
            <p className="bold">
              Required Fields* - must be completed to submit survey.
            </p>
          </Header>

          {/* change name of survey when updating form, as well as netlify form email watchers */}

          <Form
            name="survey-center-nov-09a"
            method="post"
            action="/thanks"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={handleSubmit}
            className={classes.root}
          >
            {/* The `form-name` hidden field is required to
            support form submissions without JavaScript */}
            <input type="hidden" name="form-name" value="usi-request-quote" />
            <p hidden>
              {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
              <label>
                Don’t fill this out:
                <input name="bot-field" onChange={handleChange} />
              </label>
            </p>
            <TextField
              className={classes.TextField}
              name="Franchise Number"
              label="Franchise Number"
              onChange={handleChange}
              required
              variant="outlined"
            />
            <TextField
              name="Number of Stores Reporting"
              label="Number of Stores Reporting"
              onChange={handleChange}
              required
              placeholder=""
              variant="outlined"
            />
            <TextField
              name="Email"
              label="Email"
              onChange={handleChange}
              required
              variant="outlined"
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                name="Week Ending"
                label="Week Ending"
                onChange={e => {
                  setSelectedDate(e);
                  setState({ ...state, 'Week Ending': e });
                }}
                value={selectedDate}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
            <TextField
              name="Weekly Avg Sales Per Store"
              label="Weekly Avg. Sales Per Store"
              onChange={handleChange}
              required
              variant="outlined"
            />
            <TextField
              name="Weekly Ticket Average"
              label="Weekly Ticket Average"
              onChange={handleChange}
              required
              variant="outlined"
            />
            <TextField
              name="Food Cost % Including Waste"
              label="Food Cost % Including Waste"
              onChange={handleChange}
              required
              variant="outlined"
            />
            <TextField
              name="Paper %"
              label="Paper %"
              onChange={handleChange}
              required
              variant="outlined"
            />
            <TextField
              name="Labor %"
              label="Labor %"
              onChange={handleChange}
              required
              variant="outlined"
            />
            <TextField
              name="Online Orders avg per store"
              label="Online Orders (avg. # per store)"
              onChange={handleChange}
              type="text"
              variant="outlined"
            />
            <TextField
              name="Delivery Orders avg per store"
              label="Delivery Orders(avg. # per store)"
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              name="Current Promotion"
              label="Current Promotion"
              onChange={handleChange}
              variant="outlined"
            />
            <FormControl component="fieldset">
              <FormLabel component="legend">Zone</FormLabel>
              <RadioGroup
                row
                defaultValue="top"
                aria-label="zone"
                name="Zone"
                value={radioValue}
                onChange={e => setRadioValue(e.target.value)}
              >
                <FormControlLabel value="NE" control={<Radio />} label="NE" />
                <FormControlLabel value="SE" control={<Radio />} label="SE" />
                <FormControlLabel value="MW" control={<Radio />} label="MW" />
                <FormControlLabel value="S" control={<Radio />} label="S" />
                <FormControlLabel value="NW" control={<Radio />} label="NW" />
                <FormControlLabel value="W" control={<Radio />} label="W" />
              </RadioGroup>
            </FormControl>
            <CenterRow>
              <TextField
                name="% PMH"
                label="$ PMH"
                className={classes.percentInput}
                onChange={handleChange}
                variant="outlined"
              />
              <TextField
                name="% Waste"
                label="% Waste"
                className={classes.percentInput}
                onChange={handleChange}
                variant="outlined"
              />
              <TextField
                name="% Wings"
                label="% Wings"
                className={classes.percentInput}
                onChange={handleChange}
                variant="outlined"
              />
            </CenterRow>

            <h1>Price Points</h1>
            <SubHeader>
              Fill out once (then only need changes after that)
            </SubHeader>
            <CenterRow>
              <PricePoints>
                <TextField
                  name="HNR Price Point"
                  label="HNR Price Point"
                  onChange={handleChange}
                  variant="outlined"
                />
              </PricePoints>
              <PricePoints>
                <TextField
                  name="Crazy Bread"
                  label="Crazy Bread"
                  onChange={handleChange}
                  variant="outlined"
                />
              </PricePoints>
            </CenterRow>
            <CenterRow>
              <PricePoints>
                <TextField
                  name="Crazy Combo"
                  label="Crazy Combo"
                  onChange={handleChange}
                  variant="outlined"
                />
              </PricePoints>
              <PricePoints>
                <TextField
                  name="Italian Cheese Bread"
                  label="Italian Cheese Bread"
                  onChange={handleChange}
                  variant="outlined"
                />
              </PricePoints>
              <PricePoints>
                <TextField
                  name="20 oz Soda"
                  label="20 oz Soda"
                  onChange={handleChange}
                  variant="outlined"
                />
              </PricePoints>
            </CenterRow>
            <CenterRow>
              <PricePoints>
                <TextField
                  name="2 Liter Soda"
                  label="2 Liter Soda"
                  onChange={handleChange}
                  variant="outlined"
                />
              </PricePoints>
              <PricePoints>
                <TextField
                  name="Lg. Extra Item"
                  label="Lg. Extra Item"
                  onChange={handleChange}
                  variant="outlined"
                />
              </PricePoints>
            </CenterRow>
            <TextField
              name="Comments"
              label="Comments"
              onChange={handleChange}
              multiline
              variant="outlined"
            />
            <button className="JoinNow__button" type="submit">
              submit
            </button>
          </Form>
        </SurveryCenterContainer>
      </ContactUsStyles>
    </Layout>
  );
};

export default SurveyCenter;
